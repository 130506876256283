<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center" class="mb-2">
        <v-col cols="auto" class="pr-12">
          <h1>FAQs</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search FAQs"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed color="accent" @click="openFaq()">
              <v-icon left>mdi-plus</v-icon>Add FAQ
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="faqs"
            no-data-text="No FAQs can be found"
          >
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="openFaq(item)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit/View FAQ</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    class="ml-2"
                    @click="openDelete(item)"
                  >
                    <v-icon x-small>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete FAQ</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="faqDialog.open"
      max-width="600"
      @click:outside="resetFaq()"
    >
      <v-card>
        <v-form @submit.prevent="saveFaq" method="post">
          <v-card-title
            >{{ faqDialog.isEditing ? "Edit" : "Add" }} an FAQ</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              label="Question Title"
              type="text"
              v-model="faqDialog.fields.title"
              required
              outlined
              :error="faqDialog.errors.hasOwnProperty('title')"
              :error-messages="faqDialog.errors['title']"
            ></v-text-field>
            <v-textarea
              label="Answer"
              rows="4"
              v-model="faqDialog.fields.content"
              required
              outlined
              :error="faqDialog.errors.hasOwnProperty('content')"
              :error-messages="faqDialog.errors['content']"
            ></v-textarea>
            <v-select
              label="Category"
              v-model="faqDialog.fields.category"
              :items="categories"
              required
              outlined
              :error="faqDialog.errors.hasOwnProperty('category')"
              :error-messages="faqDialog.errors['category']"
            ></v-select>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="resetFaq()">Close</v-btn>
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="faqDialog.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete FAQ</v-card-title>
        <v-card-text
          >Are you sure you want to delete
          <strong>{{ deleteDialog.faq.title }}</strong
          >?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteDialog.loading"
            @click="saveDelete"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "FAQs",
          disabled: true,
        },
      ],
      searchTerm: "",
      tableHeaders: [
        { text: "Question Title", value: "title" },
        { text: "Category", value: "category" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      faqDialog: {
        open: false,
        fields: {
          title: null,
          content: null,
          category: null,
        },
        loading: false,
        isEditing: false,
        faq: {},
        errors: {},
      },
      deleteDialog: {
        open: false,
        loading: false,
        faq: {},
      },
    };
  },

  computed: {
    faqs() {
      return this.$store.getters["arnprior/faqsStore/all"];
    },
    categories() {
      return this.$store.getters["arnprior/faqsStore/categories"];
    },
  },

  methods: {
    openFaq: function (faq = null) {
      if (faq !== null) {
        this.faqDialog.fields.title = faq.title;
        this.faqDialog.fields.content = faq.content;
        this.faqDialog.fields.category = faq.category;
        this.faqDialog.isEditing = true;
        this.faqDialog.faq = faq;
      }

      this.faqDialog.open = true;
    },

    resetFaq: function () {
      this.faqDialog.open = false;
      this.faqDialog.fields.title = null;
      this.faqDialog.fields.content = null;
      this.faqDialog.fields.category = null;
      this.faqDialog.loading = false;
      this.faqDialog.faq = {};
      this.faqDialog.errors = {};
      this.faqDialog.isEditing = false;
    },

    saveFaq() {},

    openDelete(faq) {
      this.deleteDialog.faq = faq;
      this.deleteDialog.open = true;
    },

    resetDelete() {
      this.deleteDialog.loading = false;
      this.deleteDialog.open = false;
      this.deleteDialog.faq = {};
    },

    saveDelete() {},
  },
};
</script>
